<template>
  <div class="m-news">
    <section class="m-news__filters">
      <div class="inner-wrapper">
        <div class="m-news__filters__title">
          <h1 class="title">News & Articles</h1>
        </div>
        <div v-if="categoryOptions.length > 2">
          <ul class="m-news__filters__list">
            <li class="m-news__filters__list__item"  v-for="category in categoryOptions" :key="category.id">
              <a @click="switchCategory(category)" :class="`m-news__filters__filter ${category.colour} ${activeCategory.id == category.id ? 'active' : ''}`">{{category.name}}</a>
            </li>
          </ul>
        </div>

        <div class="m-news__mobile-filters">
          <div :class="['m-news__mobile-filters__label', {'is-active' : mobileFiltersOpen}]" @click="toggleMobileFilters">
            <span class="label">{{ activeCategory.name }}</span>
            <i class="icon icon--arrow--down--blue "></i>
          </div>
          <div class="m-news__mobile-filters__list" ref="mobileFilters">
            <ul>
              <li class="m-news__mobile-filters__list-item" v-for="category in categoryOptions" :key="category.id">
                <a @click="activeCategory=category; toggleMobileFilters()" :class="`m-news__mobile-filters__filter ${activeCategory.id == category.id ? 'active' : ''}`">{{category.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="m-news__section" v-if="latestArticle">
      <div class="inner-wrapper">
        <a :href="latestArticle.url">
          <div class="c-latest-news-card one-up" :style="`background-image: url('${latestArticle.image}')`">
            <div class="c-latest-news-card__label" :class="latestArticle.category_colour">{{latestArticle.category_name}}</div>
          </div>
          <h3 class="c-latest-news-card__title one-up">{{latestArticle.title}}</h3>
        </a>
      </div>
    </section>

    <section class="m-news__section" v-if="featuredArticles && featuredArticles.length > 0">
      <div class="inner-wrapper">
        <h2 class="t-section-title">Featured</h2>
        <div class="grid grid--2-cols">
          <div class="grid-col" v-for="article in featuredArticles" :key="article.id">
            <a :href="article.url">
              <div class="c-latest-news-card two-up" :style="`background-image: url('${article.featuredThumb}')`">
                <div class="c-latest-news-card__label" :class="article.category_colour">{{article.category_name}}</div>
              </div>
              <h3 class="c-latest-news-card__title two-up">{{article.title}}</h3>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="m-news__section">
      <div class="inner-wrapper">
        <h2 class="t-section-title">Recent News</h2>
        <div class="m-news__small-grid">
          <div class="grid grid--4-cols">

            <div class="grid-col" v-for="article in articles" :key="article.id">
              <a :href="article.url">
                <div class="c-latest-news-card four-up" :style="`background-image: url('${article.thumb}')`">
                  <div class="c-latest-news-card__label" :class="article.category_colour">{{article.category_name}}</div>
                </div>
                <h3 class="c-latest-news-card__title four-up">{{article.title}}</h3>
              </a>
            </div>

          </div>

          <div class="m-news__small-grid__show-more" v-if="hasMore">
            <button type="button" class="button button--hollow--blue" @click="fetchMore">Show More</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import Api from "../utils/api"
import _ from 'lodash'

export default {
  props: ["categories", "section"],

  data() {
    return {
      articles: [],
      latestArticle: null,
      featuredArticles: [],
      activeCategory: {id: null, name: "All"},
      offset: 0,
      limit: 8,
      hasMore: false,
      loading: false,
      mobileFiltersOpen: false
    }
  },

  computed: {
    categoryOptions() {
      return [{id: null, name: "All"}, ..._.sortBy(this.categories, 'position')]
    },
  },

  mounted() {
    this.fetch();
    this.fetchFeatured()
  },  

  watch: {
    activeCategory() {
      this.fetch()
      this.fetchFeatured()
    }
  },

  methods: {
    toggleMobileFilters() {
      const menu = this.$refs.mobileFilters;
      const menuHeight = menu.scrollHeight;
      
      if(!this.mobileFiltersOpen) {
        menu.style.maxHeight = menuHeight + 'px';
        this.mobileFiltersOpen = true;
      } else {
        menu.style.maxHeight = 0;
        this.mobileFiltersOpen = false;
      }
    },

    switchCategory(category) {
      this.activeCategory = category;
    },

    fetchFeatured() {
      Api.get('/articles', { params: { 
        category_id: this.activeCategory.id,
        section: this.section,
        featured: true,
        limit: 2,
      }})
        .then((response) => {
          this.featuredArticles = response.data.articles
        }).catch((e) => {
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetch() {
      this.loading = true
      this.hasMore = false
      Api.get('/articles', { params: { 
        category_id: this.activeCategory.id,
        section: this.section,        
        offset: 0,
        limit: this.limit+1 // Plus one to grab the latest Article for the top
      }})
        .then((response) => {
          this.latestArticle = response.data.articles.shift()
          this.articles = response.data.articles
          this.offset = 1
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetchMore() {
      this.offset += this.limit;
      Api.get('/articles', { params: { 
        category_id: this.activeCategory.id,
        section: this.section,
        offset: this.offset,
        limit: this.limit
      }})
        .then((response) => {
          this.articles = [...this.articles, ...response.data.articles]
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });      
    },

    small(article) {
      return (article.title + article.month + article.year).length > 50 ? 'small' : ''
    }
  }
}
</script>