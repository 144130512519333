<template>
  <div :class="['team-member', {'is-visible': visible}]">
    <button type="button" aria-label="Close" class="team-member__close" @click="dismiss">
      <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g id="Team-Member-Profile" transform="translate(-650.000000, -22.000000)" stroke="#EE9EB1" stroke-width="2">
                  <g id="Group" transform="translate(651.000000, 23.000000)">
                      <line x1="0" y1="0" x2="26.1818182" y2="26.1818182" id="Path"></line>
                      <line x1="26.1818182" y1="0" x2="0" y2="26.1818182" id="Path"></line>
                  </g>
              </g>
          </g>
      </svg>
    </button>


    
    <div class="team-member__profile">
      <div class="team-member__image">

        <div class="shape-image">
          <img :src="activeTeamMember.image" :alt="activeTeamMember.name" />
        </div>

        <div class="shape-a">
          <svg width="113px" height="180px" viewBox="0 0 113 180" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Group-2-Copy" transform="translate(-16.768349, 0.000000)">
                      <g id="Group-14" transform="translate(17.107772, 0.000000)">
                          <polygon id="Fill-9-Copy-3" fill="#08B3A1" transform="translate(65.660576, 40.787524) rotate(39.000000) translate(-65.660576, -40.787524) " points="55.6420563 69.7875242 60.3747495 43.3596585 36.6605762 30.7690044 63.0892871 35.5008524 75.6799411 11.7875242 70.947248 38.2162351 94.6605762 50.8068892 68.2318653 46.074196"></polygon>
                          <path d="M94.3597081,157.449688 C73.4884857,173.491494 43.4756784,169.690758 27.3246897,148.960628 C11.1729463,128.229748 15.0003057,98.4205807 35.8715281,82.3780252" id="Fill-3-Copy" fill="#E35E37" transform="translate(55.844083, 124.878025) rotate(-61.000000) translate(-55.844083, -124.878025) "></path>
                      </g>
                  </g>
              </g>
          </svg>
        </div>
        <div class="shape-b">
                
          <svg width="126px" height="155px" viewBox="0 0 126 155" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Artboard-Copy" transform="translate(-48.000000, -49.000000)" stroke="#F7AA00" stroke-width="2">
                      <g id="Path-6" transform="translate(49.231651, 49.954913)">
                          <path d="M-5.06341249,41.1088971 C27.2059559,34.3379234 50.3583281,45.7870243 64.3937039,75.4561997 C78.4290798,105.125375 99.842025,117.313128 128.63254,112.019457" id="Path-5" transform="translate(61.784564, 76.221717) rotate(-65.000000) translate(-61.784564, -76.221717) "></path>
                      </g>
                  </g>
              </g>
          </svg>
        </div>

      </div>
      <div class="team-member__details">
        <h5 class="name">{{ activeTeamMember.name }}</h5>
        <p class="title">{{ activeTeamMember.role }}</p>


        <div class="team-member__bio" v-html="activeTeamMember.body"></div>

        <div class="team-member__links">
          <ul>
            <li v-if="activeTeamMember.email">
              <a :href="`mailto:${activeTeamMember.email}`" target="_blank"> 
                <svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Team-Member-Profile" transform="translate(-263.000000, -402.000000)" fill="#D2A4CB" fill-rule="nonzero">
                            <g id="Group-2" transform="translate(263.000000, 402.000000)">
                                <path d="M23.888002,1.832 C23.8196237,1.80296424 23.7404615,1.81840086 23.688002,1.871 L13.941002,11.616 C12.8671847,12.6892171 11.1268193,12.6892171 10.053002,11.616 L0.310001982,1.871 C0.257818891,1.81788452 0.178318128,1.80238187 0.110001982,1.832 C0.0429640445,1.86087526 -0.000337522907,1.92700856 0,2 L0,14 C0,15.1045695 0.895432482,16 2,16 L22,16 C23.1045715,16 24,15.1045695 24,14 L24,2 C24.0002732,1.92644459 23.9560036,1.86004015 23.888002,1.832 Z" id="Path"></path>
                                <path d="M11.115002,10.556 C11.60364,11.0432087 12.3943639,11.0432087 12.883002,10.556 L22.569002,0.87 C22.7027354,0.736260774 22.7495612,0.538509109 22.690002,0.359 C22.580002,0.03 22.274002,0 22.000002,0 L2.00000198,0 C1.72500198,0 1.41700198,0.03 1.30900198,0.359 C1.24944279,0.538509109 1.29626853,0.736260774 1.43000198,0.87 L11.115002,10.556 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>{{ activeTeamMember.email }}</span>
              </a>
            </li>
            <li v-if="activeTeamMember.telephone">
              <a :href="`tel:${activeTeamMember.telephone}`" target="_blank">
                
                <svg width="24px" height="23px" viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Team-Member-Profile" transform="translate(-264.000000, -444.000000)" fill="#D2A4CB" fill-rule="nonzero">
                            <g id="Group-2" transform="translate(263.000000, 402.000000)">
                                <path d="M23.8854458,58.4903857 L21.4334458,56.0373857 C20.9061336,55.5091049 20.1903631,55.2122384 19.4439458,55.2122384 C18.6975285,55.2122384 17.9817581,55.5091049 17.4544458,56.0373857 L16.9544458,56.5373857 C14.4901885,54.4726359 12.2139354,52.193371 10.1524458,49.7263857 L10.6524458,49.2263857 C11.7486654,48.1269501 11.7486654,46.3478214 10.6524458,45.2483857 L8.19544581,42.7983857 C7.08115926,41.7338714 5.32673237,41.7338714 4.21244581,42.7983857 L2.87244581,44.1443857 C1.54526924,45.4811178 1.33662366,47.5650698 2.37244581,49.1383857 C6.36754136,55.1582126 11.5264088,60.3181001 17.5454458,64.3143857 C19.1233125,65.3401679 21.2019492,65.1323042 22.5454458,63.8143857 L23.8904458,62.4683857 C24.4186053,61.9414152 24.7154224,61.2259758 24.7154224,60.4798857 C24.7154224,59.7337956 24.4186053,59.0183562 23.8904458,58.4913857 L23.8854458,58.4903857 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>{{ activeTeamMember.telephone }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  props: {
    activeTeamMember: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    dismiss() {
      this.$emit("dismissProfile");
    }
  }
}
</script>
