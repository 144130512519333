<template>
  <div
    class="m-cards-horizontal__carousel"
    :class="`previous-${previousIndex} position-${currentIndex}`"
  >
    <div class="mobile">
      <flickity ref="flickity" :options="flickityOptions">
        <div class="mobile-card-container"
                    :key="`mobile-card-${index}`"
            v-for="(slide, index) in carousel"
>
          <div
            class="mobile-card"
          >
            <div class="mobile-card__image" v-html="slide.imageTag"></div>
            <div class="mobile-card__content-section">
              <p class="mobile-card__section">{{ slide.section }}</p>
              <h3 class="mobile-card__title">{{ slide.title }}</h3>
              <div class="mobile-card__body" v-html="slide.body" />
              <div class="mobile-card__action">
                <a
                  :href="slide.actionUrl"
                  >{{ slide.actionText }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </flickity>
    </div>
    <div class="progress" v-if="carousel.length > 1">
      <ol class="progress__page-dots">
        <li
          v-for="(slide, index) in carousel"
          class="dot-target"
          :key="`dot-${index}`"
          @click="goToSlide(index)"
          aria-current="step"
        >
          <i class="dot" :class="`dot-${index}`"></i>
        </li>
        <li class="carousel-arrow bounce" @click="nextSlide()">
          <svg
            version="1.1"
            viewBox="0 0 26 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <g transform="translate(-1086 -688)" stroke="#C2C2DC">
                <g
                  transform="translate(1099 694) rotate(180) translate(-12 -5)"
                >
                  <polyline id="a" points="5 10 0 5 5 0" />
                  <line x2="24" y1="5" y2="5" />
                </g>
              </g>
            </g>
          </svg>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  props: ["data"],
  components: {
    Flickity,
  },
  data() {
    return {
      carousel: this.data,
      currentIndex: 0,
      previousIndex: 0,
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        adaptiveHeight: true,
      },
    };
  },
  mounted() {
    this.$refs.flickity.on("change", (o) => {
      this.currentIndex = o;
    });
    setTimeout(() => this.$refs.flickity.resize(), 100)
    setTimeout(() => this.$refs.flickity.resize(), 500)
    // setTimeout(() => this.$refs.flickity.resize(), 1000)
    setTimeout(() => this.$refs.flickity.resize(), 2000)
  },
  watch: {
    currentIndex(newIndex, oldIndex) {
      this.previousIndex = oldIndex;
      this.$refs.flickity.select(newIndex);
    },
  },
  methods: {
    nextSlide() {
      if (this.currentIndex == this.carousel.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    goToSlide(slideIndex) {
      this.currentIndex = slideIndex;
    },
  },
};
</script>
