<template>
  <div class="m-card-carousel">
    <flickity ref="flickity" :options="flickityOptions">
      <div class="m-card-carousel-cell" :class="card.colour" v-for="card in data" :key="card.id">
        <a class="card-link" :href="card.url">
          <div class="m-card-carousel__container">

            <div class="m-card-carousel__status">
              {{ card.status }} 
            </div>

            <div class="m-card-carousel__title">
              <h4 class="title">{{ card.title }}</h4>
            </div>
            <div class="m-card-carousel__image" v-if="card.image && card.image.x2">
              <img :src="card.image.x2" >
              
            </div>

            <div class="m-card-carousel__content" v-html="card.content" v-if="card.content" />

            <div class="m-card-carousel__details">
              <div class="m-card-carousel__details__item" v-if="card.date">
                <svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Module-/-Cards---Carousel" transform="translate(-247.000000, -333.000000)" fill="#6C1C73" fill-rule="nonzero">
                            <g id="Group-8" transform="translate(205.000000, 85.500000)">
                                <g id="Group-3-Copy" transform="translate(42.000000, 248.000000)">
                                    <path d="M1.62162162,10.7027027 C1.44250224,10.7027027 1.2972973,10.5574978 1.2972973,10.3783784 L1.2972973,4.86486486 C1.2972973,4.68574549 1.44250224,4.54054054 1.62162162,4.54054054 L10.3783784,4.54054054 C10.5574978,4.54054054 10.7027027,4.68574549 10.7027027,4.86486486 L10.7027027,6.20756757 C11.1330764,6.14789291 11.5696263,6.14789291 12,6.20756757 L12,1.94594595 C12,1.58770719 11.7095901,1.2972973 11.3513514,1.2972973 L9.72972973,1.2972973 C9.64017004,1.2972973 9.56756757,1.22469482 9.56756757,1.13513514 L9.56756757,0.486486486 C9.56756757,0.217807419 9.34976015,0 9.08108108,0 C8.81240201,0 8.59459459,0.217807419 8.59459459,0.486486486 L8.59459459,2.91891892 C8.59459459,3.18759799 8.37678718,3.40540541 8.10810811,3.40540541 C7.83942904,3.40540541 7.62162162,3.18759799 7.62162162,2.91891892 L7.62162162,1.62162162 C7.62162162,1.44250224 7.47641668,1.2972973 7.2972973,1.2972973 L4.54054054,1.2972973 C4.45098085,1.2972973 4.37837838,1.22469482 4.37837838,1.13513514 L4.37837838,0.486486486 C4.37837838,0.217807419 4.16057096,0 3.89189189,0 C3.62321282,0 3.40540541,0.217807419 3.40540541,0.486486486 L3.40540541,2.91891892 C3.40540541,3.18759799 3.18759799,3.40540541 2.91891892,3.40540541 C2.65023985,3.40540541 2.43243243,3.18759799 2.43243243,2.91891892 L2.43243243,1.62162162 C2.43243243,1.44250224 2.28722749,1.2972973 2.10810811,1.2972973 L0.648648649,1.2972973 C0.290409892,1.2972973 0,1.58770719 0,1.94594595 L0,10.7027027 C0,11.4191802 0.580819784,12 1.2972973,12 L6.20691892,12 C6.14724426,11.5696263 6.14724426,11.1330764 6.20691892,10.7027027 L1.62162162,10.7027027 Z" id="Path"></path>
                                    <path d="M11,7 C8.790861,7 7,8.790861 7,11 C7,13.209139 8.790861,15 11,15 C13.209139,15 15,13.209139 15,11 C14.9972874,8.79198555 13.2080144,7.00271255 11,7 Z M11,13.7692308 C9.87995071,13.7692308 8.87018868,13.0945294 8.44156437,12.0597387 C8.01294006,11.0249481 8.24986369,9.83385259 9.04185814,9.04185814 C9.83385259,8.24986369 11.0249481,8.01294006 12.0597387,8.44156437 C13.0945294,8.87018868 13.7692308,9.87995071 13.7692308,11 C13.767535,12.528701 12.528701,13.767535 11,13.7692308 Z" id="Shape"></path>
                                    <path d="M12.5714286,10.87575 L11.8571429,10.87575 L11.8571429,9.5625 C11.8571429,9.25183983 11.6652649,9 11.4285714,9 C11.191878,9 11,9.25183983 11,9.5625 L11,11.4375 C11,11.7481602 11.191878,12 11.4285714,12 L12.5714286,12 C12.808122,12 13,11.7481602 13,11.4375 C13,11.1268398 12.808122,10.875 12.5714286,10.875 L12.5714286,10.87575 Z" id="Path"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                {{ card.date }}
              </div>
              <div class="m-card-carousel__details__item" v-if="card.location">
                <svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Module-/-Cards---Carousel" transform="translate(-245.000000, -362.000000)" fill="#6C1C73" fill-rule="nonzero">
                            <g id="Group-8" transform="translate(205.000000, 85.500000)">
                                <g id="Group-2-Copy" transform="translate(40.000000, 277.000000)">
                                    <path d="M10.45375,11.0025116 C10.1097802,10.9700618 9.80743248,11.2558246 9.77843753,11.6407808 C9.74944258,12.025737 10.0047802,12.3641115 10.34875,12.3965614 C11.1415962,12.461908 11.929881,12.5841003 12.70875,12.7623858 C12.7801774,12.7802221 12.8309734,12.851153 12.8309734,12.9330572 C12.8309734,13.0149614 12.7801774,13.0858923 12.70875,13.1037286 C10.9928006,13.472768 9.2470085,13.6394542 7.5,13.6010539 C5.75320088,13.6393744 4.00762346,13.4726887 2.291875,13.1037286 C2.22044761,13.0858923 2.16965164,13.0149614 2.16965164,12.9330572 C2.16965164,12.851153 2.22044761,12.7802221 2.291875,12.7623858 C3.06249301,12.5856168 3.84241476,12.4641274 4.626875,12.3986598 C4.96320001,12.3560633 5.20811104,12.0215766 5.17933001,11.6441455 C5.15054899,11.2667144 4.85853585,10.9835095 4.520625,11.0053095 C0,11.4326876 0,12.4818971 0,12.9323577 C0,14.7985518 5.198125,15 7.5,15 C9.801875,15 15,14.7985518 15,12.9323577 C15,12.4818971 15,11.4326876 10.45375,11.0025116 Z" id="Path"></path>
                                    <path d="M7.50061183,12.9999878 C7.70918215,12.9999878 7.90335218,12.8953529 8.01577158,12.7225539 C9.21373215,10.879255 12,6.3820389 12,4.42620425 C12,1.98167914 9.98528137,0 7.5,0 C5.01471863,0 3,1.98167914 3,4.42620425 C3,6.3820389 5.78626785,10.8798568 6.98484024,12.7225539 C7.09680727,12.8961319 7.29161887,13.0009188 7.50061183,12.9999878 L7.50061183,12.9999878 Z M5.35921142,4.21377052 C5.35921142,3.05050228 6.31794904,2.10748705 7.50061183,2.10748705 C8.68327462,2.10748705 9.64201224,3.05050228 9.64201224,4.21377052 C9.64201224,5.37703875 8.68327462,6.32005398 7.50061183,6.32005398 C6.31794904,6.32005398 5.35921142,5.37703875 5.35921142,4.21377052 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                {{ card.location }}
              </div>
            </div>
          </div>
        </a>
      </div>
    </flickity>
    <template v-if="data.length > 1">
      <button
        @click="previous()"
        class="m-card-carousel__button m-card-carousel__button--prev"
      >
        <i class="icon icon--card-carousel--prev"></i>
      </button>

      <button
        @click="next()"
        class="m-card-carousel__button m-card-carousel__button--next"
      >
        <i class="icon icon--card-carousel--next"></i>
      </button>
    </template>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded';
import Flickity from "vue-flickity";

export default {
  props: {
    data: Array,
    id: Number,
  },
  components: {
    Flickity,
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
    loadComplete () {
      if (this.$refs.flickity && !this.flickityRendered) {
        this.flickityRendered = true;
        this.$refs.flickity.rerender();
        setTimeout(() => {         
          document.querySelectorAll(".m-card-carousel-cell .card-link").forEach(e => {
            if(e.offsetHeight > this.maxHeight) {
              this.maxHeight = e.offsetHeight
            }
          })
          document.querySelectorAll(".m-card-carousel-cell").forEach(e => {
            e.style.height = '100%'
          })
          this.$refs.flickity.$flickity.maxCellHeight = this.maxHeight
          document.querySelector('.flickity-viewport').style.height = this.maxHeight + 'px'
        }, 500)
      }

    }
  },

  data() {
    return {
      flickityRendered: false,
      maxHeight: 0,
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        groupCells: true,
        imagesLoaded: true,
        percentPosition: false,
        on: {
          ready: () => { 
            imagesLoaded(this.$refs.flickity, this.loadComplete) 
          }
        }
        // adaptiveHeight: true,
        // setGallerySize: false
      },
    };
  },

  computed: {
    filteredCards() {
      return this.cards.filter((test) => test);
    },
  },
};
</script>