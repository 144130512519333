<template>
  <section :id="pageModuleId" class="s-contact-form">
    <div class="inner-wrapper">
      <form action="register.html" class="contact-form" @submit="onSubmit">
        <div class="contact-form__upper">
          <div class="contact-form__left">
            <h2>{{title}}</h2>
            <div class="branding-element">
              <svg width="242px" height="422px" viewBox="0 0 242 422" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Module-/-Form---Contact" transform="translate(-175.000000, -264.000000)">
                          <g id="Group" transform="translate(175.033908, 264.346680)">
                              <path d="M213.051823,298.881241 C233.092662,330.953286 223.339144,373.198307 191.267301,393.23902 C159.195457,413.279732 116.950954,403.52668 96.9101159,371.454634" id="Stroke-67-Copy" stroke="#2A2925" stroke-width="35.463"></path>
                              <path d="M136.713933,149.622194 C158.970553,128.237545 194.355177,128.937075 215.748279,151.184901 C237.140227,173.432726 236.440421,208.80452 214.183801,230.188017" id="Fill-73-Copy" fill="#5DA3D6" transform="translate(184.029599, 182.109196) rotate(-90.000000) translate(-184.029599, -182.109196) "></path>
                              <path d="M13.8867282,228.800344 C54.4000638,227.185848 56.9464188,291.898474 97.4597544,290.283978 C137.975185,288.670536 135.427782,223.95791 175.942165,222.343414 C216.457596,220.728918 219.004998,285.441544 259.519381,283.827048" id="Stroke-75-Copy" stroke="#E35E37" stroke-width="1.182" transform="translate(136.703055, 256.313696) rotate(-57.000000) translate(-136.703055, -256.313696) "></path>
                              <polygon id="Fill-69-Copy" fill="#08B3A1" transform="translate(63.500000, 250.153320) rotate(-90.000000) translate(-63.500000, -250.153320) " points="127 273.095968 68.9956474 261.866265 40.557352 313.65332 51.7882232 255.648967 0 227.210672 58.0055213 238.441543 86.442648 186.65332 75.2117768 244.658841"></polygon>
                              <polygon id="Fill-71-Copy" fill="#F7AA00" transform="translate(148.027424, 66.495673) rotate(-44.000000) translate(-148.027424, -66.495673) " points="104.597424 116.995673 104.597424 15.9956729 191.457424 66.4949137"></polygon>
                          </g>
                      </g>
                  </g>
              </svg>              
            </div>
          </div>
          <div class="contact-form__right">

            <template v-if="success">
              <transition name="success">
                <div v-html="body" v-if="body" />
                <span class="success-message" v-else>Thanks, your message has been received</span>
              </transition>
            </template>
          
            <template v-else>
              <div v-html="intro" v-if="intro && false" class="wysiwyg" style="margin-bottom:28px;" />

              <div class="form__row" v-if="showName">
                <div class="form__field">
                  <label for="name">{{name}}</label>
                  <input type="text" name="name" id="name" required v-model="form_submission.name">
                  <div class="error" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>

              <div class="form__row" v-if="showTelephone">
                <div class="form__field">
                  <label for="telephone">
                    {{telephone}}
                    <span class="optional">(optional)</span>  
                  </label>
                  <input type="text" name="telephone" id="telephone"  v-model="form_submission.telephone">
                  <div class="error" v-if="errors.telephone">{{ errors.telephone }}</div>
                </div>
              </div>

              <div class="form__row" v-if="showEmail">
                <div class="form__field">
                  <label for="email">{{email}}</label>
                  <input type="email" name="email" id="email" required v-model="form_submission.email">
                  <div class="error" v-if="errors.email">{{ errors.email }}</div>
                </div>
              </div>

              <div class="form__row" v-if="showOrganisation">
                <div class="form__field">
                  <label for="organisation">
                    {{organisation}}
                  </label>
                  <input type="text" name="organisation" id="organisation" required v-model="form_submission.organisation">
                  <div class="error" v-if="errors.organisation">{{ errors.organisation }}</div>
                </div>
              </div>

              <div class="form__row" v-if="showMessage">
                <div class="form__field">
                  <label for="message">{{message}}</label>
                  <textarea name="message" id="message" cols="30" rows="10" v-model="form_submission.message"></textarea>
                  <div class="error" v-if="errors.message || errors.base">{{ errors.message || errors.base }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="contact-form__lower" v-if="!success">
          <button type="submit" :disabled="isSending" class="button button--filled--orange">{{isSending ? 'Sending...' : button}}</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Api from "../utils/api";
import { VueReCaptcha } from 'vue-recaptcha-v3'


export default {
  props: {
    pageModuleId: { type: Number },
    title: { },
    name: { default: "Name" },
    telephone: { default: "Telephone" },
    email: { default: "Email" },
    message: { default: "Your Message" },
    organisation: { default: "Organization"},
    body: {},
    intro: {},
    button: { default: "Send"},
    redirectUrl: {},
    showName: { type: Boolean },
    showTelephone: { type: Boolean },
    showEmail: { type: Boolean },
    showMessage: { type: Boolean },
    showOrganisation: { type: Boolean},
  },
  
  data: function() {
    return {
      form_submission: {
        name: '',
        telephone: '',
        email: '',
        organisation: '',
        message: '',
      },
      errors: {},
      success: false,
      isSending: false,
    }
  },

  methods: {
	  clearForm() {
			for (let field in this.form_submission) {
				this.form_submission[field] = ''
      }
      this.errors = {}
		},

		async onSubmit(evt) {
			evt.preventDefault();

      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('contactform')

      this.isSending = true;
      
      Api.post('/form_submissions', { 
        form_submission: 
          {
            ...this.form_submission, 
            page_module_id: this.pageModuleId,
          },
          token: token,          
        })
        .then((response) => {
          this.clearForm();
          this.isSending = false;
          this.success = true;

          if(this.redirectUrl) {
            window.location = this.redirectUrl
          }         

          // setTimeout(() => {
          //   this.success = false; // Fade out success message after a short white
          // }, 4000);
        }).catch((e) => {
          this.isSending = false;
          console.log(e.response)
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}    
}
</script>

<style lang="scss" scoped>
  .success-message {
    padding: 22px 28px 28px 20px;
  }

  .branding-element {
    position: relative;
    top: 100px;
    left: -25px;

    @media screen and (max-width: 660px) {
      display: none;
    }
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }

  .error {
    padding-top: 10px;
    font-size: 90%;
    line-height: 150%;
    color: #E9260F;
    font-weight: bold;
  }
</style>